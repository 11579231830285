import { User } from '@/domain/entity/user.entity';
import { computed, ref } from 'vue';

export type CurrentUser = User;
const localUser: CurrentUser | null = JSON.parse(localStorage.getItem('user') || 'null');
export const localToken = localStorage.getItem('token');

export interface UserState {
  currentUser: CurrentUser | null,
  token: string,
}

const currentUser = ref<CurrentUser | null>(localUser || null);
const token = ref<string | null>(localToken);

export function useCurrentUser() {
  return {
    setUser(user: CurrentUser) {
      currentUser.value = user;
      localStorage.setItem('user', JSON.stringify(user));
    },
    removeUser() {
      currentUser.value = null;
      localStorage.removeItem('user');
    },
    setToken(newToken: string) {
      token.value = newToken;
      localStorage.setItem('token', newToken);
    },
    removeToken() {
      token.value = '';
      localStorage.removeItem('token');
    },
    user: computed(() => currentUser.value),
    token: computed(() => token.value),
    isLogged: computed(() => !!currentUser.value),
  };
}
