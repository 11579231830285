import { createApp } from 'vue';
import { VueQueryPlugin } from 'vue-query';
import VueDatePicker from '@vuepic/vue-datepicker';
import RippleJs from '@/plugins/ripple';
import vuetify from '@/plugins/vuetify';
import App from './App.vue';
import router from './router/index';

import './assets/styles/theme.css';
import '@vuepic/vue-datepicker/dist/main.css';

createApp(App)
  .directive('ripple', RippleJs)
  .use(router)
  .use(VueQueryPlugin)
  .use(vuetify)
  .component('vue-date-picker', VueDatePicker)
  .mount('#app');
