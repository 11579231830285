import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import { useCurrentUser } from '@/domain/states/useCurrentUser';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/Login/LoginView.vue'),
    meta: { pageTitle: 'Login' },
  },
  {
    path: '/reset/password/',
    name: 'ResetPassword',
    component: () => import('../views/Login/ResetPassword.vue'),
    props: (route) => route.params,
    meta: { pageTitle: 'Recuperar Senha' },
  },
  {
    path: '/recover/password',
    name: 'RecoverPassword',
    component: () => import('../views/Login/RecoverPassword.vue'),
    props: (route) => route.params,
    meta: { pageTitle: 'Recuperar Senha' },
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'Redirect',
    redirect: {name: 'Login'},
    meta: {pageTitle: 'Login'}
  },
  {
    path: '/home',
    name: 'Home',
    component: () => import('../views/Home/HomeView.vue'),
    redirect: { name: 'UserList' },
    meta: { userAuth: true, pageTitle: 'Usuários' },
    children: [
      {
        path: '/users',
        name: 'UserList',
        component: () => import('../views/Users/UserList.vue'),
        meta: { userAuth: true, pageTitle: 'Usuários' },
      },
      {
        path: '/users/create',
        name: 'UserCreate',
        component: () => import('../views/Users/UserCreate.vue'),
        meta: { userAuth: true },
      },
      {
        path: '/users/:id/edit',
        name: 'UserEdit',
        component: () => import('../views/Users/UserCreate.vue'),
        props: (route) => route.params,
        meta: { userAuth: true },
      },
      {
        path: '/materials',
        name: 'MaterialList',
        component: () => import('../views/Materials/MaterialList.vue'),
        meta: { userAuth: true, pageTitle: 'Materiais' },
      },
      {
        path: '/materials/create',
        name: 'MaterialCreate',
        component: () => import('../views/Materials/MaterialCreate.vue'),
        meta: { userAuth: true },
      },
      {
        path: '/materials/:id/edit',
        name: 'MaterialEdit',
        component: () => import('../views/Materials/MaterialCreate.vue'),
        props: (route) => route.params,
        meta: { userAuth: true },
      },
      {
        path: '/customers',
        name: 'CustomerList',
        component: () => import('../views/Customers/CustomerList.vue'),
        props: (route) => route.params,
        meta: { userAuth: true, pageTitle: 'Clientes' },
      },
      {
        path: '/customers/create',
        name: 'CustomerCreate',
        component: () => import('../views/Customers/CustomerCreate.vue'),
        meta: { userAuth: true },
      },
      {
        path: '/customers/:id/edit',
        name: 'CustomerEdit',
        component: () => import('../views/Customers/CustomerCreate.vue'),
        props: (route) => route.params,
        meta: { userAuth: true },
      },
      {
        path: '/destinations',
        name: 'DestinationList',
        component: () => import('../views/Destinations/DestinationList.vue'),
        meta: { userAuth: true, pageTitle: 'Destinos' },
      },
      {
        path: '/destinations/create',
        name: 'DestinationCreate',
        component: () => import('../views/Destinations/DestinationCreate.vue'),
        meta: { userAuth: true },
      },
      {
        path: '/destinations/:id/edit',
        name: 'DestinationEdit',
        component: () => import('../views/Destinations/DestinationCreate.vue'),
        props: (route) => route.params,
        meta: { userAuth: true },
      },
      {
        path: '/cargos',
        name: 'CargoList',
        component: () => import('../views/Cargos/CargoList.vue'),
        meta: { userAuth: true, pageTitle: 'Cargas' },
      },
      {
        path: '/cargos/create',
        name: 'CargoCreate',
        component: () => import('../views/Cargos/CargoCreate.vue'),
        meta: { userAuth: true },
      },
      {
        path: '/cargos/:id/edit',
        name: 'CargoEdit',
        component: () => import('../views/Cargos/CargoCreate.vue'),
        props: (route) => route.params,
        meta: { userAuth: true },
      },
      {
        path: '/drivers',
        name: 'DriverList',
        component: () => import('../views/Drivers/DriverList.vue'),
        meta: { userAuth: true, pageTitle: 'Motoristas' },
      },
      {
        path: '/drivers/create',
        name: 'DriverCreate',
        component: () => import('../views/Drivers/DriverCreate.vue'),
        meta: { userAuth: true },
      },
      {
        path: '/drivers/:id/edit',
        name: 'DriverEdit',
        component: () => import('../views/Drivers/DriverCreate.vue'),
        props: (route) => route.params,
        meta: { userAuth: true },
      },
      {
        path: '/trucks',
        name: 'TruckList',
        component: () => import('../views/Trucks/TruckList.vue'),
        meta: { userAuth: true, pageTitle: 'Caminhões' },
      },
      {
        path: '/trucks/create',
        name: 'TruckCreate',
        component: () => import('../views/Trucks/TruckCreate.vue'),
        meta: { userAuth: true },
      },
      {
        path: '/trucks/:id/edit',
        name: 'TruckEdit',
        component: () => import('../views/Trucks/TruckCreate.vue'),
        props: (route) => route.params,
        meta: { userAuth: true },
      },
      {
        path: '/scale-groups',
        name: 'ScaleGroupList',
        component: () => import('../views/ScaleGroups/ScaleGroupList.vue'),
        meta: { userAuth: true, pageTitle: 'Grupo de Escalas' },
      },
      {
        path: '/scale-groups/create',
        name: 'ScaleGroupCreate',
        component: () => import('../views/ScaleGroups/ScaleGroupCreate.vue'),
        meta: { userAuth: true },
      },
      {
        path: '/scale-groups/:id/edit',
        name: 'ScaleGroupEdit',
        component: () => import('../views/ScaleGroups/ScaleGroupCreate.vue'),
        props: (route) => route.params,
        meta: { userAuth: true },
      },
      {
        path: '/scales',
        name: 'ScaleList',
        component: () => import('../views/Scales/ScaleList.vue'),
        props: (route) => route.query,
        meta: { userAuth: true, pageTitle: 'Escalas' },
      },
      {
        path: '/scales/create',
        name: 'ScaleCreate',
        component: () => import('../views/Scales/ScaleCreate.vue'),
        props: (route) => route.query,
        meta: { userAuth: true },
      },
      {
        path: '/scales/:id/edit',
        name: 'ScaleEdit',
        component: () => import('../views/Scales/ScaleCreate.vue'),
        props: (route) => route.params,
        meta: { userAuth: true },
      },
      {
        path: '/scale/cooperates',
        name: 'ScaleCooperateList',
        component: () => import('../views/Scales/ScaleCooperateList.vue'),
        props: (route) => route.query,
        meta: { userAuth: true, pageTitle: 'Cooperados' },
      },
      {
        path: '/scale-group-tickets',
        name: 'ScaleGroupListForTickets',
        component: () => import('../views/Tickets/ScaleGroupListForTickets.vue'),
        props: (route) => route.query,
        meta: { userAuth: true, pageTitle: 'Escalas de tickets' },
      },
      {
        path: '/scale-tickets',
        name: 'ScaleListForTickets',
        component: () => import('../views/Tickets/ScaleListForTickets.vue'),
        props: (route) => route.query,
        meta: { userAuth: true, pageTitle: 'Escalas de tickets' },
      },
      {
        path: '/tickets',
        name: 'TicketList',
        component: () => import('../views/Tickets/TicketList.vue'),
        props: (route) => route.query,
        meta: { userAuth: true, pageTitle: 'Tickets' },
      },
      {
        path: '/tickets/create',
        name: 'TicketCreate',
        component: () => import('../views/Tickets/TicketCreate.vue'),
        props: (route) => route.query,
        meta: { userAuth: true, pageTitle: 'Tickets' },
      },
      {
        path: '/tickets/:id/edit',
        name: 'TicketEdit',
        component: () => import('../views/Tickets/TicketCreate.vue'),
        props: (route) => route.params,
        meta: { userAuth: true, pageTitle: 'Tickets' },
      },
      {
        path: '/services',
        name: 'ServiceList',
        component: () => import('../views/Services/ServiceList.vue'),
        meta: { userAuth: true, pageTitle: 'Serviços' },
      },
      {
        path: '/services/create',
        name: 'ServiceCreate',
        component: () => import('../views/Services/ServiceCreate.vue'),
        props: (route) => route.query,
        meta: { userAuth: true, pageTitle: 'Serviços' },
      },
      {
        path: '/services/:id/edit',
        name: 'ServiceEdit',
        component: () => import('../views/Services/ServiceCreate.vue'),
        props: (route) => route.params,
        meta: { userAuth: true, pageTitle: 'Serviços' },
      },
      {
        path: '/invoices',
        name: 'InvoiceList',
        component: () => import('../views/Invoice/InvoiceList.vue'),
        meta: { userAuth: true, pageTitle: 'Notas de Pagamento' },
      },
      {
        path: '/invoices/create',
        name: 'InvoiceCreate',
        component: () => import('../views/Invoice/InvoiceCreate.vue'),
        meta: { userAuth: true, pageTitle: 'Notas de Pagamento' },
      },
      {
        path: '/invoices/:id/edit',
        name: 'InvoiceEdit',
        component: () => import('../views/Invoice/InvoiceCreate.vue'),
        props: (route) => route.params,
        meta: { userAuth: true, pageTitle: 'Notas de Pagamento' },
      },

      {
        path: '/operational',
        name: 'OperationalList',
        component: () => import('../views/Operational/OperationalList.vue'),
        meta: { userAuth: true, pageTitle: 'Operacional' },
      },
      {
        path: '/cooperated/:id/movements',
        name: 'CooperatedScaleHistory',
        component: () => import('../views/Operational/CooperatedScaleHistory.vue'),
        props: (route) => route.params,
        meta: { userAuth: true, pageTitle: 'Movimentacões de Escalas do Cooperado ' },
      },
      {
        path: '/user/info',
        name: 'UserInfo',
        component: () => import('../views/Users/UserInfo.vue'),
        props: (route) => route.query,
        meta: { userAuth: true, pageTitle: 'Informações do Cooperado' },
      },
      {
        path: '/announcements',
        name: 'AnnouncementList',
        component: () => import('../views/Announcement/AnnouncementList.vue'),
        props: (route) => route.params,
        meta: { userAuth: true, pageTitle: 'Lista de Editais' },
      },
      {
        path: '/announcements/create',
        name: 'AnnouncementCreate',
        component: () => import('../views/Announcement/AnnouncementCreate.vue'),
        props: (route) => route.params,
        meta: { userAuth: true, pageTitle: 'Lista de Editais' },
      },
      {
        path: '/announcements/edit/:id',
        name: 'AnnouncementEdit',
        component: () => import('../views/Announcement/AnnouncementCreate.vue'),
        props: (route) => route.params,
        meta: { userAuth: true, pageTitle: 'Lista de Editais' },
      },
      {
        path: '/financial-transactions/',
        name: 'FinancialTransactionList',
        component: () => import('../views/FinancialTransactions/FinancialTransactionList.vue'),
        meta: { userAuth: true, pageTitle: 'Movimentações Financeiras' },
      },
      {
        path: '/financial-transactions/create',
        name: 'FinancialTransactionCreate',
        component: () => import('../views/FinancialTransactions/FinancialTransactionCreate.vue'),
        meta: { userAuth: true },
      },
      {
        path: '/financial-transactions/:id/edit',
        name: 'FinancialTransactionEdit',
        component: () => import('../views/FinancialTransactions/FinancialTransactionCreate.vue'),
        props: (route) => route.params,
        meta: { userAuth: true },
      },
      {
        path: '/reports',
        name: 'Report',
        component: () => import('../views/report/Report.vue'),
        props: (route) => route.params,
        meta: { userAuth: true, pageTitle: 'Relatórios' },
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  const { token } = useCurrentUser();
  const isRouteNeedAuth = to.matched.some((record) => record.meta.userAuth);
  if (isRouteNeedAuth && !token.value) return next({ name: 'Login' });
  return next();
});

router.afterEach((to) => {
  const pageTitle = to.meta.pageTitle as string | undefined;
  document.title = pageTitle ?? 'Erro';
  if (!to.meta.noScrollReset) window.scrollTo(0, 0);
});
export default router;
