import { computed, CSSProperties, Ref, ref } from 'vue';

export type HEX = `#${string}`;

export interface Colors {
  primary: HEX;
  secondary: HEX;
  accent: HEX;
  success: HEX;
  error: HEX;
  warning: HEX;
  gray: HEX;
  lightGray: HEX;
  lighterGray: HEX;
  white: HEX;
  black: HEX;
  red: HEX;
  [key: string]: HEX;
}

export type Color = (keyof Colors)| CSSProperties['color'] | HEX | string;

export interface ThemeStoreType {
  colors: Colors,
}

function registerCssColors(color: Colors) {
  const style = document.createElement('style');
  style.type = 'text/css';
  document.getElementsByTagName('head')[0].appendChild(style);

  Object.keys(color).forEach((key) => {
    document.documentElement.style.setProperty(`--${key}`, color[key as keyof typeof color]);
    style.sheet?.insertRule(`.${key}{background-color: var(--${key})`, 0);
    style.sheet?.insertRule(`.text-${key}{color: var(--${key})`, 0);
  });
}
const theme = ref<ThemeStoreType>({
  colors: {
    primary: '#4CAF50',
    secondary: '#2E7D32',
    accent: '#F3E600',
    success: '#00BA88',
    error: '#F44336',
    warning: '#F4B740',
    gray: '#DCE4EF',
    lightGray: '#D2D2D2',
    lighterGray: '#E5E5E5',
    white: '#FFFFFF',
    black: '#000000',
    red: '#E50019',
    blue: '#3B568C',
    background: '#FFFFFF',
    backgroundDark: '#EEEEEE',
    backgroundTransparent: '#EEEEEE80',
    backgroundDrawer: '#373435',
    cooperatingColor: '#2E7D32',
    accountingColor: '#F4B740',
    operationalColor: '#00BA88',
    adminColor: '#3B568C',
    receptionistColor: '#F44336',

    textPrimary: '#424242',
    textSecondary: '#9E9E9E',

    primaryLight: '#7EA1BE',
    primaryDark: '#003765',

    warningLight: '#FFD789',
    warningDark: '#946200',

    errorLight: '#FF5F5F',
    errorDark: '#A31A1A',

    successLight: '#34EAB9',
    successDark: '#00966D',

    grayLighter: '#F6F8FB',
    grayLight: '#EDF2F7',
    grayDark: '#CAD7E6',
    grayDarker: '#B9C9DE',

    title: '#14142B',
    body: '#4E4B66',
    label: '#6E7191',
    placeholder: '#A0A3BD',
    line: '#D9DBE9',
    inputBackground: '#EFF0F7',
    offWhite: '#FCFCFC',
  },
});
registerCssColors(theme.value.colors);

function hexToRgb(hex: HEX) {
  const rgb = [hex.substring(1, 3), hex.substring(3, 5), hex.substring(5, 7)];
  return {
    r: parseInt(rgb[0], 16),
    g: parseInt(rgb[1], 16),
    b: parseInt(rgb[2], 16),
  };
}

function colorToGray(color: HEX) {
  const rgb = hexToRgb(color);
  return Math.round((rgb.r * 299) + (rgb.g * 587) + (rgb.b * 114)) / 1000;
}

export function useTheme() {
  function contrastColor(color: HEX) {
    const gray = colorToGray(color);
    return gray > 127 ? theme.value.colors.black : theme.value.colors.white;
  }

  function resolveColor(color: Color) {
    return theme.value.colors[color as keyof Colors] || color;
  }

  return {
    colors: computed(() => theme.value.colors),
    breakPoints: {},
    resolveColor,
    contrastColor,
  };
}
